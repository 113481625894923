import React from 'react';
import Content from '../views/LandingFleet/Price';

export default ({location}) => <Content location={location} seo={{
  title: 'Se en pris på vores flådestyringstjeneste',
  lang: 'da-DK',
  description: 'Vil du have en pris på flådestyring? Udfyld vores korte formular og modtag et tilbud.',
  meta: [{
    name: 'keywords',
    content: 'Pris'
  },
  {
    name: 'robots',
    content: 'noindex',
  }]
}}/>